<template>
  <div class="flexdc flex1">
    <div>
      <div style="display:flex; height:100%">
        <div style="flex:1; width:60%">
          <div class="operationControl">
            <div class="searchbox" style="padding-left:0">
              <div title="课程名称" class="searchboxItem ci-full">
                <span class="itemLabel">课程名称:</span>
                <el-input
                    v-model="title"
                    type="text"
                    size="small"
                    placeholder="请输入课程名称"
                    clearable
                />
              </div>
              <div>
                <el-button
                    type="primary"
                    class="bgc-bv"
                    round
                    @click="getData()"
                >查询
                </el-button>
              </div>
            </div>
          </div>
          <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              @row-click="clickRow"
              stripe
          >
            <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
            />
            <el-table-column
                label="课程名称"
                align="left"
                prop="title"
            />
            <el-table-column
                label="单价"
                align="center"
                show-overflow-tooltip
                prop="price"
            >
              <template slot-scope="scoped">
                {{scoped.row.price+'元'}}
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
          <PageNum
              :apiData="apiData"
              @sizeChange="sizeChange"
              @getData="getData"
          />
        </div>
        <div style="display:flex; flex-direction: column;flex:1;width:40%">
          <div style="flex:1;display: flex;" class="ovy-a">
            <div v-if="!rowId" class="flex1 flexdcc">
              <img
                  src="../../assets/recomEmpty.png"
                  alt
                  class
                  style="width: 12rem;"
              />
              <span>请在左侧选择您想要配置的课程</span>
            </div>
            <div v-else style="height: 10px;">
              <h3 style="padding-bottom:20px;">基础设置</h3>
              <div class="lessonNum">
                <span>视频时长：{{ getTime(duration) || 0 }}</span>
                <span>课程单价：{{ price }}元</span>
<!--                <span-->
<!--                >选修课总学时：{{-->
<!--                    (lessonNum - price).toFixed(1) || 0-->
<!--                  }}学时</span-->
<!--                >-->
              </div>
              <div>
                <p class="lessNumcount">
                  <span>售卖单价：</span>
                  <el-input-number
                      v-model="saveData.price"
                      @change="handleChange"
                      size="small"
                      controls-position="right"
                      step="1"
                      :precision="2"
                      :min="0"
                  ></el-input-number>
                  元
                </p>
                <p class="lessNumcount">
                  <span>是否启用课程有效期：</span>
                  <el-radio-group
                          v-model="saveData.validPattern"
                          class="set-food-select"
                          size="small"
                      >
                        <el-radio :label="item.value" v-for="(item,index) in codeList" :key="index">{{ item.label }}</el-radio>
                  </el-radio-group>
                </p>
                <p class="lessNumcount" v-if="saveData.validPattern == '20'">
                  <span>课程有效期：</span>
                  <el-input-number
                      v-model="saveData.validDay"
                      @change="handleChange"
                      size="small"
                      controls-position="right"
                      step="1"
                      :precision="0"
                      :min="0"
                  ></el-input-number>
                  &nbsp;天
                </p>
<!--                <p style="display: flex;align-items: center;">-->
<!--                  <span>视频累计时长：{{ getTime(duration) }}</span>-->
<!--                </p>-->
              </div>
              <div v-if="trainTypeId == '16' && projectSource == '20'">
                <el-form
                    :model="ruleFormWork"
                    :rules="rulesWork"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                  <el-form-item label="工种选择" prop="workType">
                    <el-select
                        v-model="ruleFormWork.workType"
                        placeholder="请选择工种"
                        style="width:40%"
                    >
                      <el-option
                          v-for="item in typeofWorkList"
                          :key="item.sourceProjectCourseId"
                          :label="item.sourceIndustryName"
                          :value="item.sourceProjectCourseId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <h3 style="padding-bottom:10px;">章节编排</h3>
<!--              <el-button class="bgc-bv" @click="selectChange" size="mini"-->
<!--              >反选</el-button-->
<!--              >-->
              <div style="display: flex;">
                <el-tree
                    ref="tree"
                    :data="data"
                    node-key="id"
                    default-expand-all
                    :props="defaultProps"
                >
                  <span class="custom-tree-node" slot-scope="{ data }">
                    <span
                        v-text="data.title"
                        class="ellipsis"
                        :title="data.title"
                    >
                    </span>
                    <el-button
                        v-if="data.audition == 1&&data.live_url"
                        type="text"
                        @click="showVideo(data)"
                        size="mini"
                        style="margin:0 1rem"
                    >预览</el-button>

                  </span>
                </el-tree>
              </div>
            </div>
          </div>
          <div
              style="display: flex; justify-content: center;padding-top:17px"
              v-show="rowId"
          >
            <el-button @click="$router.back()" class="bgc-bv">取消</el-button>
            <el-button @click="getCheckedNodes" class="bgc-bv">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <PaperShow ref="PaperShow" />
    <el-dialog
        v-if="dialogVisible1"
        :title="videotitle"
        :visible.sync="dialogVisible1"
        width="60%"
        :before-close="handleClose"
    >
      <player_1
          :videoId="videoId"
          :kpointSource="kpointSource"
      />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import PaperShow from "@/views/resourse/popup/PaperShow";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import player_1 from "@/components/player";
import { createSwigger, distorySwigger } from "../../utils/swigger";
export default {
  components: {
    Empty,
    PageNum,
    PaperShow,
    tree,
    player_1,
  },
  mixins: [List],
  data() {
    return {
      rowId: "",
      data: [],
      title: "",
      defaultProps: {
        id: "id",
        children: "children",
        label: "id",
      },

      duration: 0,
      price: 0,
      saveData: {
        price: undefined,
        validPattern: '20',
        validDay: '365',
      },
      codeList: [],

      projectId: "",
      lessonNumDemand: "",
      datatreeList: [],
      trainTypeId: "",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      selects: [],
      params: {},
      // ruleForm: {
      //   Trainingtype: "",
      // },
      chapterSchema: true,
      stu: false,
      buyId: "",
      notifyBody: {},
      videoId: "", // 視頻id
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      typeofWorkList: [],
      ruleFormWork: {
        workType: "",
      },
      rulesWork:{
        workType:[{required:true,message:'请选择工种',trigger:'change'}]
      }
    };
  },
  props: {
    projectCourseId: {
      type: Number,
    },
  },
  watch: {

  },
  created() {
    this.projectSource = sessionStorage.getItem("projectSource");
    this.getCodeValue()
  },
  mounted() {

  },
  methods: {
    // PROJECT_VALID_PATTERN
    getCodeValue() {
      const codeList = this.$setDictionary("PROJECT_VALID_PATTERN", "list");
      for (const key in codeList) {
        this.codeList.push({
          value: key,
          label: codeList[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: 10,
        pageSize: this.pageSize,
        title: this.title || "",
        projectId: sessionStorage.getItem("projectId"),
      };
      this.doFetch({
        url: "/biz/project/rrs/course/page",
        params,
        pageNum,
      });
    },
    // 点击列表行
    clickRow(row) {
        this.rowId = row.rrsCourseId;
        this.getDetail(row.rrsCourseId);
        // this.getTreeTrain()
        // this.$nextTick(() => {
        //   this.$refs.tree.setCheckedNodes(this.data);
        // });

    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    /**
     * 保存
     */
    getCheckedNodes() {
      const cClessonNum = sessionStorage.getItem("cClessonNum");
      const projectSource = sessionStorage.getItem("projectSource");
      // let checked = [];
      // checked = this.$refs.tree
      //     .getCheckedKeys()
      //     .concat(this.$refs.tree.getHalfCheckedKeys());
      if (this.saveData.price == "") {
        this.$message({
          message: "请输入售卖单价",
          type: "warning",
        });
        return false;
      }
      if (this.validPattern == '20'&&this.saveData.validDay == "") {
        this.$message({
          message: "请输入课程有效期",
          type: "warning",
        });
        return false;
      }

      let parmar = {
        rrsCourseId: this.rowId,
        projectId: sessionStorage.getItem("projectId"),
        price: this.saveData.price,
        validPattern: this.saveData.validPattern
      };
      if(this.saveData.validPattern == '20'){
        parmar.validDay = this.saveData.validDay
      }

      this.$post("/biz/rrs/project/course/add",
          parmar
      )
          .then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.$notify.close();
              sessionStorage.setItem("refresh", 10);
              this.$router.push({
                path: "/web/ClassdetailsListBj",
                query: {
                  active: "second",
                },
              });
            }
          })
          .catch((err) => {
            return;
          });
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      distorySwigger();
    },
    // 预览
    showVideo(data) {
      console.log(data)
      let _this = this;
      //单元测试打开是题库
      // if (data.list[0].kpointVideoId) {
      //   _this
      //       .$post("/biz/courseware/auth", {
      //         videoId: data.id,
      //       })
      //       .then((result) => {
              _this.videoId = data.live_url;
              // _this.videoType = false;
              // _this.videoTime = 0;
              _this.videotitle = data.title;
              _this.kpointSource = '30';
              _this.dialogVisible1 = true;
            // });
      // }
      // if (!data.list[0].kpointVideoId && data.paperId) {
      //   this.showPaper(data);
      // }
    },
    /**
     *获取 详情
     */
    getDetail(rowId) {
      this.$post("/biz/project/rrs/course/details", {
        rrsCourseId: rowId,
      }).then((result) => {
        if (result.status === "0") {
          this.data = result.data.chapter_list
          this.price = result.data.price
          this.duration = result.data.duration
          this.saveData.price = result.data.price
          this.saveData.validPattern = '20'
          this.saveData.validDay = '365'
          // this.selects = result.data.allKey.map(Number);
          // this.buyId = result.data.tree.buyId;
          // this.lessonNum = result.data.tree.lessonNum.toFixed(1);
          // this.price = result.data.tree.lessonNum.toFixed(1);
          // this.lessonNumDemand = result.data.tree.lessonNum.toFixed(1);
          // this.duration = result.data.tree.duration;
        }
      });
    },
    // creatData(data = {}) {
    //   let list = [];
    //   this.chapterSchema = data.chapterSchema;
    //   // if (data.chapterSchema) {
    //   //   // 样式一 ------> 三层
    //   //   data.nodes.forEach((element) => {
    //   //     let nodeList = [];
    //   //     list.push({
    //   //       courseKpointId: element.courseKpointId,
    //   //       catalogName: element.catalogName,
    //   //       kpointDuration: element.kpointDuration,
    //   //       kpointDurationStr: element.kpointDurationStr,
    //   //       parentNodeId: element.parentNodeId,
    //   //       children: nodeList,
    //   //       level: 1,
    //   //       requiredState: false,
    //   //     });
    //   //     (element.child || []).forEach((obj) => {
    //   //       nodeList.push(this.created2dom(obj));
    //   //     });
    //   //   });
    //   // } else {
    //   data.nodes.forEach((element) => {
    //     list.push(this.created2dom(element));
    //   });
    //   // }
    //   this.data = list;
    // },
    // created2dom(obj) {
    //   let children = [];
    //   if (obj.kpointId && obj.paperId) {
    //     children = [
    //       {
    //         catalogName: obj.paperName,
    //         courseKpointId: obj.courseKpointId,
    //         sourceCourseKpointId: obj.courseKpointId,
    //         level: 3,
    //         paperId: obj.paperId,
    //       },
    //     ];
    //   }
    //   let listArr = [];
    //   if (obj.kpointVideoId) {
    //     obj.list = listArr;
    //     listArr.push({
    //       kpointId: obj.kpointId,
    //       kpointDuration: obj.kpointDuration,
    //       kpointDurationStr: obj.kpointDurationStr,
    //       kpointVideoId: obj.kpointVideoId,
    //       buyId: obj.buyId,
    //       kpointSource: obj.kpointSource,
    //     });
    //   } else if (obj.paperId) {
    //     obj.list = listArr;
    //     listArr.push({
    //       paperId: obj.paperId,
    //     });
    //   }
    //   //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
    //   if (obj.kpointId) {
    //     return {
    //       catalogName: obj.catalogName,
    //       kpointDuration: obj.kpointDuration,
    //       kpointDurationStr: obj.kpointDurationStr,
    //       parentNodeId: obj.parentNodeId,
    //       kpointLessonNum: obj.kpointLessonNum,
    //       level: 2,
    //       list: listArr,
    //       children: children,
    //       requiredState: obj.requiredState,
    //       section: obj.section,
    //       courseKpointId: obj.courseKpointId,
    //       sourceCourseId: this.courseId,
    //       sourceCourseKpointId: obj.courseKpointId,
    //       kpointId: obj.kpointId,
    //       buyId: obj.buyId,
    //     };
    //   } else {
    //     return {
    //       catalogName: obj.catalogName,
    //       kpointDuration: obj.kpointDuration,
    //       kpointDurationStr: obj.kpointDurationStr,
    //       parentNodeId: obj.parentNodeId,
    //       kpointLessonNum: obj.kpointLessonNum,
    //       level: 2,
    //       list: listArr,
    //       children: [],
    //       requiredState: obj.requiredState,
    //       section: obj.section,
    //       courseKpointId: obj.courseKpointId,
    //       sourceCourseId: this.courseId,
    //       sourceCourseKpointId: obj.courseKpointId,
    //       kpointId: obj.kpointId,
    //       buyId: obj.buyId,
    //       paperId: obj.paperId,
    //       paperName: obj.paperName,
    //     };
    //   }
    // },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 32;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
          (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    //反选
    // selectChange() {
    //   let res = this.$refs.tree;
    //   let nodes = res.getCheckedNodes(true, true);
    //   this.batchSelect(this.data, res, true, nodes);
    // },
    batchSelect(nodes, refs, flag, selecteds) {
      if (typeof nodes != "undefined") {
        nodes.forEach((element) => {
          refs.setChecked(element, flag, true);
        });
      }
      if (typeof selecteds != "undefined") {
        selecteds.forEach((node) => {
          refs.setChecked(node, !flag, true);
        });
      }
    },
    // getTreeTrain() {
    //   this.$post("/biz/sou/cc/querySourceProjectCourseList",{
    //     projectId:sessionStorage.getItem("projectId"),
    //     isAll:false,
    //     projectCourseId:this.projectCourseId
    //   }).then((ret) => {
    //     this.typeofWorkList = ret.data;
    //     this.ruleFormWork.workType = ret.data[0].sourceProjectCourseId
    //   });
    // }
  },
};
</script>
<style lang="less">
.el-input-number__decrease {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
<style lang="less" scoped>
.el-tree {
  width: 100%;
}

.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}

.lessNumcount {
  //display: flex;
  //align-items: center;
  padding: 0 2rem 15px;
  span {
    width: 10rem;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tree-node {
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0px;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 25rem;
  font-size: 14px;
}

.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 23.85rem;
  font-size: 14px;
}

.el-table .warning-row {
  background: red;
}

.el-table .success-row {
  background: #ffffff;
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
</style>
